@import url("https://fonts.googleapis.com/css?family=Luckiest+Guy");
/* BODY */

/* JUMP */
.ani {
  cursor: default;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  display: block;
  text-align: center;
}

.ani span {
  position: relative;
  top: 20px;
  display: inline-block;
  -webkit-animation: bounce 0.3s ease infinite alternate;
  font-size: 80px;
  color: #fff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
    0 5px 0 #ccc, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
    0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
}

.ani span:nth-child(2) {
  -webkit-animation-delay: 0.1s;
}

.ani span:nth-child(3) {
  -webkit-animation-delay: 0.2s;
}

.ani span:nth-child(4) {
  -webkit-animation-delay: 0.3s;
}

.ani span:nth-child(5) {
  -webkit-animation-delay: 0.4s;
}

.ani span:nth-child(6) {
  -webkit-animation-delay: 0.5s;
}

.ani span:nth-child(7) {
  -webkit-animation-delay: 0.6s;
}

.ani span:nth-child(8) {
  -webkit-animation-delay: 0.2s;
}

.ani span:nth-child(9) {
  -webkit-animation-delay: 0.3s;
}

.ani span:nth-child(10) {
  -webkit-animation-delay: 0.4s;
}

.ani span:nth-child(11) {
  -webkit-animation-delay: 0.5s;
}

.ani span:nth-child(12) {
  -webkit-animation-delay: 0.6s;
}

.ani span:nth-child(13) {
  -webkit-animation-delay: 0.7s;
}

.ani span:nth-child(14) {
  -webkit-animation-delay: 0.8s;
}

/* ANIMATION */
@-webkit-keyframes bounce {
  100% {
    top: -20px;
    text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
      0 5px 0 #ccc, 0 6px 0 #ccc, 0 7px 0 #ccc, 0 8px 0 #ccc, 0 9px 0 #ccc,
      0 50px 25px rgba(0, 0, 0, 0.2);
  }
}