@import url(https://fonts.googleapis.com/css?family=Montserrat);

html, body{
  height: 100%;
  font-weight: 800;
}



svg {
    display: block;
    font: 10.5em 'Montserrat';
    width: 100%;
    height: 450px;
    margin: 0 auto;
}

.text-copy {
    fill: none;
    stroke: white;
    stroke-dasharray: 6% 29%;
    stroke-width: 5px;
    stroke-dashoffset: 0%;
    animation: stroke-offset 5.5s infinite linear;
}

.text-copy:nth-child(1){
	stroke: rgb(34, 34, 34);
	animation-delay: -1;
}

.text-copy:nth-child(2){
	stroke: rgb(55, 67, 77);
	animation-delay: -2s;
}

.text-copy:nth-child(3){
	stroke: rgb(235, 27, 114);
	animation-delay: -3s;
}

.text-copy:nth-child(4){
	stroke: rgba(40, 181, 225, 0.6);
	animation-delay: -4s;
}

.text-copy:nth-child(5){
	stroke: #FDB731;
	animation-delay: -5s;
}

@keyframes stroke-offset{
	100% {stroke-dashoffset: -35%;}
}